import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Ny lag ger polisen rätt att installera spionprogram på mobiler: `}<a parentName="li" {...{
          "href": "https://www.nyteknik.se/sakerhet/skarp-kritik-mot-polisens-plan-pa-virusprogram-i-krypterade-appar-6987818"
        }}>{`https://www.nyteknik.se/sakerhet/skarp-kritik-mot-polisens-plan-pa-virusprogram-i-krypterade-appar-6987818`}</a>{` (tips från: Lars @ telegram)`}</li>
      <li parentName="ul">{`GitHub cli i beta: `}<a parentName="li" {...{
          "href": "https://github.blog/2020-02-12-supercharge-your-command-line-experience-github-cli-is-now-in-beta/"
        }}>{`https://github.blog/2020-02-12-supercharge-your-command-line-experience-github-cli-is-now-in-beta/`}</a></li>
      <li parentName="ul">{`MWC inställt pga Coronaviruset: `}<a parentName="li" {...{
          "href": "https://www.gsma.com/newsroom/press-release/gsma-statement-on-mwc-barcelona-2020/"
        }}>{`https://www.gsma.com/newsroom/press-release/gsma-statement-on-mwc-barcelona-2020/`}</a>{` (tips från: Bittin @ telegram)`}</li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Google varnar för installation av Chrome-plugins på nya Edge: `}<a parentName="li" {...{
          "href": "https://9to5google.com/2020/02/21/google-chrome-microsoft-edge-extensions-warning/"
        }}>{`https://9to5google.com/2020/02/21/google-chrome-microsoft-edge-extensions-warning/`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`React-icons: `}<a parentName="li" {...{
          "href": "https://github.com/react-icons/react-icons"
        }}>{`https://github.com/react-icons/react-icons`}</a></li>
      <li parentName="ul">{`Markdown/MDX: `}<a parentName="li" {...{
          "href": "https://mdxjs.com/"
        }}>{`https://mdxjs.com/`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <h3>{`Alex PinePhone`}</h3>
    <ul>
      <li parentName="ul">{`Sakta men säkert`}</li>
    </ul>
    <h3>{`Sebs Linuxäventyr`}</h3>
    <ul>
      <li parentName="ul">{`Ominstallation Manjaro + Plasma på NVMe-disk`}</li>
      <li parentName="ul">{`Buggar med updates`}</li>
      <li parentName="ul">{`VPN-setup krångligt`}</li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Går även att tipsa via GitHub-issues`}</li>
    </ul>
    <h3>{`Contributor-ligan`}</h3>
    <h4>{`Alex`}</h4>
    <ul>
      <li parentName="ul">{`Brons: 1`}</li>
      <li parentName="ul">{`Silver: 0`}</li>
      <li parentName="ul">{`Guld: 0`}</li>
    </ul>
    <h4>{`Seb`}</h4>
    <ul>
      <li parentName="ul">{`Brons: 1`}</li>
      <li parentName="ul">{`Silver: 0`}</li>
      <li parentName="ul">{`Guld: 0`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Cursed Intro - Dark Dramatic Epic Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Mystical Sting" av Alexander Nakarada`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0 (`}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a>{`)`}</li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      